export default [
    {
        header: 'CloudCart',
    },
    {
        title: 'Orders',
        route: 'orders',
        icon: 'ShoppingCartIcon'
    },
    {
        title: 'Products',
        icon: 'BoxIcon',
        children: [
            {
                title: 'Products',
                route: 'products',
            },
            {
                title: 'Categories',
                route: 'categories',
            },
            {
                title: 'Characteristics',
                route: 'products.characteristics',
            },
        ]
    },

]